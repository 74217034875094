// Import necessary dependencies and components
import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Spinner from '../react-elements/Spinner'; 

// Import CSS files
import './BookingWidget.css';
import "react-datepicker/dist/react-datepicker.css";


// Import SVG icons
import geolocationIcon from '../../svgs/geolocation-icon.svg'
import pinIcon from '../../svgs/pin-icon.svg';
import pinIconWhite from '../../svgs/pin-icon-white.svg';
import headerLogo from '../../svgs/header-logo.svg';

// Import constants and utility functions
import haversine from 'haversine-distance';

// Define the Location interface
interface Location {
    id: number;
    title: { rendered: string };
    acf: {
        booking_widget_code: string;
        ot_rid: string;
        nice_address: string;
        phone_number: string;
        opening_times: string;
        map_location: {
            lat: string;
            lng: string;
        };
    };
    distance?: number;
}

// Extend the global Window interface to include locationsData
declare global {
  interface Window {
    locationsData: Location[];
  }
}

// Update the component props interface
interface BookingWidgetProps {
  bookingPopupContent?: string;
}

const BookingWidget: React.FC<BookingWidgetProps> = ({ bookingPopupContent }) => {
  // State variables
  const [locationsData, setLocationsData] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [isChangingLocation, setIsChangingLocation] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>(() => {
    // Initialize selected time to the next available 30-minute slot
    const now = new Date();
    const minutes = now.getMinutes();
    const hours = now.getHours();
    
    if (minutes < 30) {
      return `${String(hours).padStart(2, '0')}:30`;
    } else {
      const nextHour = (hours + 1) % 24;
      return `${String(nextHour).padStart(2, '0')}:00`;
    }
  });
  const [selectedPeople, setSelectedPeople] = useState<string>('2');
  const [isMobile, setIsMobile] = useState(false);

  // Ref for the autocomplete input
  const autocompleteRef = useRef<HTMLInputElement>(null);

  // Ref for storing the timeout ID
  const autocompleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Function to process coordinates and sort locations by distance
  const processCoordinates = useCallback((latitude: number, longitude: number) => {
    const sortedLocations = locationsData.map(location => {
      const cardLat = parseFloat(location.acf.map_location.lat);
      const cardLon = parseFloat(location.acf.map_location.lng);
      const distance = haversine(
        { latitude, longitude },
        { latitude: cardLat, longitude: cardLon }
      );
      return { ...location, distance };
    }).sort((a, b) => a.distance - b.distance);

    setLocationsData(sortedLocations);
    
    // Set the closest location as the active location
    if (sortedLocations.length > 0 && window.innerWidth > 1199) {
        handleLocationChange(sortedLocations[0]);
    }
  }, [locationsData]);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];
      setIsMobile(mobileKeywords.some(keyword => userAgent.includes(keyword)));
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Function to initialize Google Autocomplete
  const initializeAutocomplete = useCallback(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, {
        types: ['geocode'],
        componentRestrictions: { country: 'uk' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setErrorMessage('No details available for the selected location.');
          setShowError(true);
          return;
        }

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        processCoordinates(latitude, longitude);
        setErrorMessage('');
        setShowError(false);
      });
    } else {
      // Retry after 500ms if Google API is not yet loaded
      autocompleteTimeoutRef.current = setTimeout(initializeAutocomplete, 500);
    }
  }, [processCoordinates]);

  // Effect to initialize Google Maps Autocomplete
  useEffect(() => {
    initializeAutocomplete();

    // Cleanup function to clear the timeout when component unmounts
    return () => {
      if (autocompleteTimeoutRef.current) {
        clearTimeout(autocompleteTimeoutRef.current);
      }
    };
  }, [initializeAutocomplete]);

  // Effect to initialize locations data and set up event listener
  useEffect(() => {
    // Use the data provided by Astro
    setLocationsData(window.locationsData);

    // Add event listener for custom event
    const handleLocationUpdateEvent = (event: CustomEvent) => {
      console.log('handleLocationUpdateEvent');
      const locationId = event.detail.locationId;
      const location = window.locationsData.find(loc => loc.id === parseInt(locationId, 10));
      if (location) {
        // Move the selected location to the top of the list
        const updatedLocations = [
          location,
          ...window.locationsData.filter(loc => loc.id !== location.id)
        ];

        // Reset scroll position of locations list
        const locationsListInner = document.querySelector('.locations-list-inner');
        if (locationsListInner) {
            locationsListInner.scrollTop = 0;
        }
        setLocationsData(updatedLocations);
        setSelectedLocation(location);
      } else {
        setSelectedLocation(null);
      }
    };

    window.addEventListener('updateBookingLocation', handleLocationUpdateEvent as EventListener);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('updateBookingLocation', handleLocationUpdateEvent as EventListener);
    };
  }, []);

  // Function to reset the search
  const handleReset = () => {
    setErrorMessage('');
    setShowError(false);
    setLocationsData(window.locationsData);
    setSelectedLocation(null); // Ensure the selected location is also reset

    // Clear the address input field
    if (autocompleteRef.current) {
      autocompleteRef.current.value = '';
    }
  };

  // Function to handle geolocation
  const handleGeolocation = () => {
    setIsLocating(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          processCoordinates(latitude, longitude);
          setIsLocating(false);
        },
        (error) => {
          console.error('Geolocation error:', error);
          setErrorMessage('Geolocation failed. Please select an address.');
          setShowError(true);
          setIsLocating(false);
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported. Please select an address.');
      setShowError(true);
      setIsLocating(false);
    }
  };

  // Function to handle location change
  const handleLocationChange = (location: Location) => {
    setIsChangingLocation(true);
    if(selectedLocation){ // If location already selected, add a delay before switching to get the 'load' effect
        setTimeout(() => {
            setSelectedLocation(location);
            setIsChangingLocation(false);
          }, 450); 
    } else {
        setSelectedLocation(location);
        setIsChangingLocation(false);
    }
  };

  // Function to handle booking submission
  const handleBookingSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!selectedDate || !selectedTime || !selectedPeople || !selectedLocation) {
      alert('Please select a date, time, and number of people before booking.');
      return;
    }

    document.dispatchEvent(new CustomEvent('track-meta-event', {
      bubbles: true,
      detail: { eventName: 'Booking Intention' }
    }));

    const dateTime = `${selectedDate.toISOString().split('T')[0]}T${selectedTime}`;
    const url = `https://www.opentable.co.uk/restref/client/?rid=${selectedLocation.acf.ot_rid}&restref=${selectedLocation.acf.ot_rid}&partysize=${selectedPeople}&datetime=${dateTime}&lang=en-GB&ot_source=Restaurant%20website&color=1`;

    window.open(url, '_blank');
  };

  return (
    <div className="booking-widget | p-4 lg:p-6 bg-black rounded-[20px] grid grid-cols-1 xl:grid-cols-2 xl:gap-6">      
      {/* Location selector section */}
      <div className={`location-selector bg-[#dbd0c5] rounded-[20px] ${selectedLocation ? 'selected-location' : 'no-location'}`}>
        <div className="inner | p-4 xl:p-8 grid xl:grid-cols-2 xl:block">
          
          {/* Locations list */}
          <div className="locations-list | relative w-full mb-4 ">
            <p className="text-black text-md font-normal font-copy text-left leading-[27px] tracking-wide mb-2">Choose location:</p>
            <div className="locations-list-inner | h-[250px] overflow-y-scroll bg-white rounded-[15px]">
              {locationsData.map((location) => (
                location.acf.ot_rid && (
                  <div 
                    className={`location-row cursor-pointer px-3 py-1 border-b border-[#dbd0c5] relative ${selectedLocation && selectedLocation.id === location.id ? 'bg-red text-white' : ''}`} 
                    key={location.id} 
                    onClick={() => handleLocationChange(location)} 
                    data-lat={location.acf.map_location.lat} 
                    data-lon={location.acf.map_location.lng}
                    role="button"
                    aria-label={`Select location ${location.title.rendered}`}
                    aria-pressed={selectedLocation && selectedLocation.id === location.id}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault(); // Prevent default behavior
                        handleLocationChange(location);
                      }
                    }}
                  >
                    <p>
                      <span className="text-[22px] font-medium font-copy capitalize leading-snug">{location.title.rendered}</span>
                      {location.distance && (
                        <p className="inline-block ml-3 px-2 py-1 text-white text-sm font-normal font-copy leading-[14px] rounded-full bg-black">
                          {(location.distance / 1609.34).toFixed(1)} ml 
                        </p>
                      )}
                    </p>
                    <div className="flex gap-x-2 items-center mb-4">
                      <img 
                        src={selectedLocation && selectedLocation.id === location.id ? pinIconWhite.src : pinIcon.src} 
                        className="flex-none" 
                        alt="Pin icon" 
                      />
                      <p className="text-[17px] font-normal font-copy underline leading-relaxed">
                        {location.acf.nice_address}
                      </p>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>

          {/* Address search form */}
          <div className="booking-widget-address-search">
            <label htmlFor="booking-widget-address" className="text-black text-md font-normal font-copy text-left leading-[27px] tracking-wide mb-2">Search address:</label>
            
            <div className={`js__address-search flex mb-4 relative ${isLocating ? "pointer-events-none" : ''}`}>
              <div className="relative flex-grow">  
                <input 
                  type="text" 
                  name="address" 
                  id="booking-widget-address"
                  placeholder="Enter address" 
                  ref={autocompleteRef}
                  autoComplete="off"
                  className={`w-full z-20 relative px-2 xl:px-4 py-2 text-black border-2 border-[#646464] bg-[#fff] ${selectedLocation ? 'rounded-l-[15px] rounded-r-none' : 'rounded-[15px]'} leading-[33px] focus:outline-none`}
                />
                
                {/* Conditionally render the Geolocation button */}
                {!selectedLocation && (
                  <div 
                    className={`booking-widget-form-geolocation | z-30 cursor-pointer absolute top-3 right-2 lg:right-4`}
                    onClick={handleGeolocation}
                  >
                    <img 
                      className="inline-block align-middle w-[20px] h-[20px] mr-2" 
                      src={geolocationIcon.src} 
                      alt="Geolocation Icon. Click to share location." 
                    />
                    <p 
                      className={`inline-block align-middle text-red text-xl font-normal font-copy leading-[33px] ${isLocating ? 'max-w-[400px]' : 'max-w-0'} overflow-hidden transition-all duration-750`}
                    >
                      Locating... 
                    </p>
                  </div>
                )}
                
                {/* Loading spinner */}
                {isLocating && (
                  <span className="top-[-10px] z-40 block absolute left-0 right-0 mx-auto">
                    <Spinner />
                  </span>
                )} 
                
                {/* Error message display */}
                <div className={`error-message | bg-black w-full rounded-b-[15px] absolute pt-6 px-2 pb-2 z-10 transition-all duration-300 ease-in-out ${showError ? 'active' : ''}`}>
                  <p className="text-white text-sm font-normal font-copy tracking-wide">
                    {errorMessage}
                  </p>
                </div>
              </div>
              
              {/* Reset button */}
              {selectedLocation && (
                <div 
                  className="form-reset | cursor-pointer bg-white border-2 border-[#646464] border-l-0 rounded-r-[15px] text-[#646464] text-lg font-normal font-copy underline leading-[27px] px-6 py-2 hover:text-red transition-colors duration-250"
                  onClick={handleReset}
                >
                  Reset
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Booking form section */}
      <div className={`form | bg-red rounded-[20px] relative overflow-hidden ${selectedLocation ? 'selected-location' : 'no-location'}`}>
        <div className="inner | pt-7 px-4 pb-4 xl:p-8">
          
          {/* Loading overlay */}
          <div className={`w-full h-full hidden xl:grid items-center bg-black absolute top-0 left-0 justify-center transition-all z-50 duration-450 ${selectedLocation && !isChangingLocation ? '-translate-y-full' : ''}`}>
            <img src={headerLogo.src} alt="Pho logo" />
          </div>
          
          {selectedLocation && (
            <div className="reset-location | absolute right-4 top-3 cursor-pointer" onClick={() => {
                handleReset(); // Optional: You can also call handleReset here if you want to reset when clicking 'Reset Location'
            }}>
              <p className="text-white text-sm font-normal font-copy leading-[14px] underline">Reset Location</p>
            </div>
          )}
          
          {/* Booking form */}
          {!isLoading && selectedLocation && !isChangingLocation && (
            <div>
              <p className="col-span-2 text-white text-[32px] font-medium font-copy capitalize leading-snug mb-4">
                {selectedLocation.title.rendered}
              </p>
              <form className="booking-form | grid grid-cols-2 gap-y-2 gap-x-4">
                <input type="hidden" name="rid" value={selectedLocation?.acf.ot_rid} />

                {/* Date picker */}
                <div className="col-span-2">
                  <label htmlFor="date" className="block text-white text-[13px] font-normal font-copy uppercase leading-[38px] tracking-wide">
                    Date
                  </label>
                  {isMobile ? (
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={selectedDate.toISOString().split('T')[0]}
                      onChange={(e) => setSelectedDate(new Date(e.target.value))}
                      min={new Date().toISOString().split('T')[0]}
                      className="w-full block px-5 py-3 text-black text-lg font-normal font-copy leading-[27px] border border-black"
                    />
                  ) : (
                    <DatePicker 
                      name="date"
                      id="date"
                      showIcon
                      toggleCalendarOnIconClick
                      selected={selectedDate}
                      onChange={(date: Date) => setSelectedDate(date)}
                      minDate={new Date()}
                      className="w-full block !px-5 !py-3 text-black text-lg font-normal font-copy leading-[27px] border border-black" 
                    />
                  )}
                </div>
                
                {/* Time selector */}
                <div className="">
                  <label htmlFor="time" className="block text-white text-[13px] font-normal font-copy uppercase leading-[38px] tracking-wide">
                    Time
                  </label>
                  <select
                    name="time"
                    id="time"
                    required
                    className="w-full px-5 py-3 text-black text-lg font-normal font-copy leading-[27px] border border-black"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  >
                    {/* Time options */}
                    <option value="00:00">12:00AM</option>
                    <option value="00:30">12:30AM</option>
                    <option value="01:00">1:00AM</option>
                    <option value="01:30">1:30AM</option>
                    <option value="02:00">2:00AM</option>
                    <option value="02:30">2:30AM</option>
                    <option value="03:00">3:00AM</option>
                    <option value="03:30">3:30AM</option>
                    <option value="04:00">4:00AM</option>
                    <option value="04:30">4:30AM</option>
                    <option value="05:00">5:00AM</option>
                    <option value="05:30">5:30AM</option>
                    <option value="06:00">6:00AM</option>
                    <option value="06:30">6:30AM</option>
                    <option value="07:00">7:00AM</option>
                    <option value="07:30">7:30AM</option>
                    <option value="08:00">8:00AM</option>
                    <option value="08:30">8:30AM</option>
                    <option value="09:00">9:00AM</option>
                    <option value="09:30">9:30AM</option>
                    <option value="10:00">10:00AM</option>
                    <option value="10:30">10:30AM</option>
                    <option value="11:00">11:00AM</option>
                    <option value="11:30">11:30AM</option>
                    <option value="12:00">12:00PM</option>
                    <option value="12:30">12:30PM</option>
                    <option value="13:00">1:00PM</option>
                    <option value="13:30">1:30PM</option>
                    <option value="14:00">2:00PM</option>
                    <option value="14:30">2:30PM</option>
                    <option value="15:00">3:00PM</option>
                    <option value="15:30">3:30PM</option>
                    <option value="16:00">4:00PM</option>
                    <option value="16:30">4:30PM</option>
                    <option value="17:00">5:00PM</option>
                    <option value="17:30">5:30PM</option>
                    <option value="18:00">6:00PM</option>
                    <option value="18:30">6:30PM</option>
                    <option value="19:00">7:00PM</option>
                    <option value="19:30">7:30PM</option>
                    <option value="20:00">8:00PM</option>
                    <option value="20:30">8:30PM</option>
                    <option value="21:00">9:00PM</option>
                    <option value="21:30">9:30PM</option>
                    <option value="22:00">10:00PM</option>
                    <option value="22:30">10:30PM</option>
                    <option value="23:00">11:00PM</option>
                    <option value="23:30">11:30PM</option>
                  </select>
                </div>
                
                {/* Number of people selector */}
                <div className="">
                  <label htmlFor="people" className="block text-white text-[13px] font-normal font-copy uppercase leading-[38px] tracking-wide">
                    Number of People
                  </label>
                  <select 
                    name="people"
                    id="people"
                    required
                    className="w-full px-5 py-3 text-black text-lg font-normal font-copy leading-[27px] border border-black"
                    value={selectedPeople}
                    onChange={(e) => setSelectedPeople(e.target.value)}
                  >
                    {[...Array(20)].map((_, i) => (
                      <option key={i + 1} value={(i + 1).toString()}>
                        {i + 1} {i === 0 ? 'Person' : 'People'}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* Book Now button */}
                <div className="col-span-2 pt-8">
                   <button
                    id={"ot-book-now"}
                    className={`c__button react group inline-block relative overflow-hidden font-copy text-white text-base text-[22px] md:text-xl font-medium leading-[30px] md:leading-[40px] large-button-black w-full text-center js__booking}`}
                    onClick={handleBookingSubmit}
                >
                    <span
                        className="l__header-nav-item-initial |  py-1 xl:py-0 px-4 md:px-3 block transition-transform duration-450 group-hover:-translate-y-full"
                        dangerouslySetInnerHTML={{ __html: "Book Now" }}
                    />
                    <span
                        className="l__header-nav-item-hover|  py-1 xl:py-0 px-4 md:px-3 w-full block absolute top-full left-0 transition-transform duration-450 group-hover:-translate-y-full w-full"
                        aria-hidden="true"
                        dangerouslySetInnerHTML={{ __html: "Book Now" }}
                    />
                </button>
                
                </div>

                {/* Optional booking popup content display */}
                {bookingPopupContent && (
                  <div className="col-span-2 py-4">
                    <p className="text-[#eee4da] text-lg font-normal font-copy leading-relaxed">{bookingPopupContent}</p>
                  </div>
                )}
                
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingWidget;